<!--
* @description:
* @fileName tpl_shop.vue
* @author hvv
* @date 2022/02/15 16:15:54
!-->
<template>
  <div class="shopItem">
    <img :src="res.coverUrl" alt="" class="imgBox" />
    <div class="contentBox">
      <div class="name">{{ res.shopName }}</div>
    </div>
  </div>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  export default defineComponent({
    name: 'tpl_shop.vue',
    props: {
      res: {
        type: Object,
      },
    },
    setup() {},
  })
</script>
<style lang="scss" scoped>
  .shopItem {
    display: flex;
    margin-bottom: 20px;
    .imgBox {
      width: 88px;
      height: 88px;
      background: #d8d8d8;
      border: 1px solid #e2e2e2;
    }
    .contentBox {
      box-sizing: border-box;
      width: calc(100% - 88px);
      height: 88px;
      padding: 10px;
    }
  }
</style>
